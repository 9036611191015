export const siteData = {
  "name": "OFTERAM",
  "address": "25 RUE OLYMPE DE GOUGES,",
  "postCode": "92600",
  "city": "asnieres",
  "phone": "0943015390",
  "url": "https://ofteram.store",
  "urlSub": "ofteram.store",
  "price": "price_1Q54L0JxeKVDK1zwhDzhSeDg",
  "pk": "pk_live_51Q54ETJxeKVDK1zwIty3JH8FZxuZ9zlc78d04D0znHhRFZueDHjdcpzMpCA7RM44Q7yzNk05a46w1DkG3m5YfgZD00MxWtchJz",
  "sk": "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"
};